/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { CalendarService } from './services/CalendarService';
import { CalendarCustomerService } from './services/CalendarCustomerService';
import { CalendarPublicService } from './services/CalendarPublicService';
import { CourseService } from './services/CourseService';
import { CourseCustomerService } from './services/CourseCustomerService';
import { CourseGroupService } from './services/CourseGroupService';
import { CourseGroupPublicService } from './services/CourseGroupPublicService';
import { CoursePublicService } from './services/CoursePublicService';
import { CrispService } from './services/CrispService';
import { CustomerService } from './services/CustomerService';
import { CustomerAdminService } from './services/CustomerAdminService';
import { CustomerCustomerService } from './services/CustomerCustomerService';
import { CustomerPublicService } from './services/CustomerPublicService';
import { DefaultService } from './services/DefaultService';
import { InstructorService } from './services/InstructorService';
import { InstructorAdminService } from './services/InstructorAdminService';
import { InstructorCustomerService } from './services/InstructorCustomerService';
import { InstructorPublicService } from './services/InstructorPublicService';
import { InvitationService } from './services/InvitationService';
import { LegalService } from './services/LegalService';
import { LegalPublicService } from './services/LegalPublicService';
import { LocationService } from './services/LocationService';
import { LocationPublicService } from './services/LocationPublicService';
import { MediaService } from './services/MediaService';
import { MediaAdminService } from './services/MediaAdminService';
import { MediaCustomerService } from './services/MediaCustomerService';
import { MediaPublicService } from './services/MediaPublicService';
import { MeetingService } from './services/MeetingService';
import { MeetingPublicService } from './services/MeetingPublicService';
import { MultipassService } from './services/MultipassService';
import { MultipassCustomerService } from './services/MultipassCustomerService';
import { MultipassPublicService } from './services/MultipassPublicService';
import { NotificationService } from './services/NotificationService';
import { NotificationAdminService } from './services/NotificationAdminService';
import { OrganizationService } from './services/OrganizationService';
import { OrganizationAdminService } from './services/OrganizationAdminService';
import { OrganizationPublicService } from './services/OrganizationPublicService';
import { PaymentService } from './services/PaymentService';
import { PaymentAdminService } from './services/PaymentAdminService';
import { PaymentCustomerService } from './services/PaymentCustomerService';
import { PaymentPlatformService } from './services/PaymentPlatformService';
import { PaymentPublicService } from './services/PaymentPublicService';
import { ProfileCustomerService } from './services/ProfileCustomerService';
import { RebateService } from './services/RebateService';
import { RebateCustomerService } from './services/RebateCustomerService';
import { RebatePublicService } from './services/RebatePublicService';
import { SchedulingService } from './services/SchedulingService';
import { SurveyService } from './services/SurveyService';
import { SurveyCustomerService } from './services/SurveyCustomerService';
import { SurveyPublicService } from './services/SurveyPublicService';
import { TagService } from './services/TagService';
import { TagPublicService } from './services/TagPublicService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class QoursesClient {
    public readonly calendar: CalendarService;
    public readonly calendarCustomer: CalendarCustomerService;
    public readonly calendarPublic: CalendarPublicService;
    public readonly course: CourseService;
    public readonly courseCustomer: CourseCustomerService;
    public readonly courseGroup: CourseGroupService;
    public readonly courseGroupPublic: CourseGroupPublicService;
    public readonly coursePublic: CoursePublicService;
    public readonly crisp: CrispService;
    public readonly customer: CustomerService;
    public readonly customerAdmin: CustomerAdminService;
    public readonly customerCustomer: CustomerCustomerService;
    public readonly customerPublic: CustomerPublicService;
    public readonly default: DefaultService;
    public readonly instructor: InstructorService;
    public readonly instructorAdmin: InstructorAdminService;
    public readonly instructorCustomer: InstructorCustomerService;
    public readonly instructorPublic: InstructorPublicService;
    public readonly invitation: InvitationService;
    public readonly legal: LegalService;
    public readonly legalPublic: LegalPublicService;
    public readonly location: LocationService;
    public readonly locationPublic: LocationPublicService;
    public readonly media: MediaService;
    public readonly mediaAdmin: MediaAdminService;
    public readonly mediaCustomer: MediaCustomerService;
    public readonly mediaPublic: MediaPublicService;
    public readonly meeting: MeetingService;
    public readonly meetingPublic: MeetingPublicService;
    public readonly multipass: MultipassService;
    public readonly multipassCustomer: MultipassCustomerService;
    public readonly multipassPublic: MultipassPublicService;
    public readonly notification: NotificationService;
    public readonly notificationAdmin: NotificationAdminService;
    public readonly organization: OrganizationService;
    public readonly organizationAdmin: OrganizationAdminService;
    public readonly organizationPublic: OrganizationPublicService;
    public readonly payment: PaymentService;
    public readonly paymentAdmin: PaymentAdminService;
    public readonly paymentCustomer: PaymentCustomerService;
    public readonly paymentPlatform: PaymentPlatformService;
    public readonly paymentPublic: PaymentPublicService;
    public readonly profileCustomer: ProfileCustomerService;
    public readonly rebate: RebateService;
    public readonly rebateCustomer: RebateCustomerService;
    public readonly rebatePublic: RebatePublicService;
    public readonly scheduling: SchedulingService;
    public readonly survey: SurveyService;
    public readonly surveyCustomer: SurveyCustomerService;
    public readonly surveyPublic: SurveyPublicService;
    public readonly tag: TagService;
    public readonly tagPublic: TagPublicService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.0-local-dev',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.calendar = new CalendarService(this.request);
        this.calendarCustomer = new CalendarCustomerService(this.request);
        this.calendarPublic = new CalendarPublicService(this.request);
        this.course = new CourseService(this.request);
        this.courseCustomer = new CourseCustomerService(this.request);
        this.courseGroup = new CourseGroupService(this.request);
        this.courseGroupPublic = new CourseGroupPublicService(this.request);
        this.coursePublic = new CoursePublicService(this.request);
        this.crisp = new CrispService(this.request);
        this.customer = new CustomerService(this.request);
        this.customerAdmin = new CustomerAdminService(this.request);
        this.customerCustomer = new CustomerCustomerService(this.request);
        this.customerPublic = new CustomerPublicService(this.request);
        this.default = new DefaultService(this.request);
        this.instructor = new InstructorService(this.request);
        this.instructorAdmin = new InstructorAdminService(this.request);
        this.instructorCustomer = new InstructorCustomerService(this.request);
        this.instructorPublic = new InstructorPublicService(this.request);
        this.invitation = new InvitationService(this.request);
        this.legal = new LegalService(this.request);
        this.legalPublic = new LegalPublicService(this.request);
        this.location = new LocationService(this.request);
        this.locationPublic = new LocationPublicService(this.request);
        this.media = new MediaService(this.request);
        this.mediaAdmin = new MediaAdminService(this.request);
        this.mediaCustomer = new MediaCustomerService(this.request);
        this.mediaPublic = new MediaPublicService(this.request);
        this.meeting = new MeetingService(this.request);
        this.meetingPublic = new MeetingPublicService(this.request);
        this.multipass = new MultipassService(this.request);
        this.multipassCustomer = new MultipassCustomerService(this.request);
        this.multipassPublic = new MultipassPublicService(this.request);
        this.notification = new NotificationService(this.request);
        this.notificationAdmin = new NotificationAdminService(this.request);
        this.organization = new OrganizationService(this.request);
        this.organizationAdmin = new OrganizationAdminService(this.request);
        this.organizationPublic = new OrganizationPublicService(this.request);
        this.payment = new PaymentService(this.request);
        this.paymentAdmin = new PaymentAdminService(this.request);
        this.paymentCustomer = new PaymentCustomerService(this.request);
        this.paymentPlatform = new PaymentPlatformService(this.request);
        this.paymentPublic = new PaymentPublicService(this.request);
        this.profileCustomer = new ProfileCustomerService(this.request);
        this.rebate = new RebateService(this.request);
        this.rebateCustomer = new RebateCustomerService(this.request);
        this.rebatePublic = new RebatePublicService(this.request);
        this.scheduling = new SchedulingService(this.request);
        this.survey = new SurveyService(this.request);
        this.surveyCustomer = new SurveyCustomerService(this.request);
        this.surveyPublic = new SurveyPublicService(this.request);
        this.tag = new TagService(this.request);
        this.tagPublic = new TagPublicService(this.request);
    }
}

