import { qoursesApi } from '@/api/qourses.tsx'
import EmptyState from '@/components/EmptyState.tsx'
import MediaImageUploadDropzone from '@/components/MediaImageUploadDropzone.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popModal, pushModal } from '@/components/modals/index.tsx'
import NotificationBubble from '@/components/NotificationBubble.tsx'
import useGetMediaImages from '@/hooks/mediaImages/useGetMediaImages.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shadcn/components/ui/tabs.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { getImageUrl } from '@/utils.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import { Images, ScanEye, Trash, Undo2, Upload, VenetianMask } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MediaImageEntity } from '../../../qourses-api-client'

export default function MediaImagePickerModal({
  selectedImagesCallback,
  multiple = false,
  maxImages = 1,
  browseOnly = false,
  initialSelectedImages = [], // Add the new prop with a default empty array
}: {
  selectedImagesCallback: (mediaImages: MediaImageEntity[]) => void
  multiple?: boolean
  maxImages?: number
  browseOnly?: boolean
  initialSelectedImages?: MediaImageEntity[] // Add type for the new prop
}) {
  const { mediaImages, isLoading, isError, refetch, isEmptyMediaImages } = useGetMediaImages()
  const [activeTab, setActiveTab] = useState<string>('imageGallery')

  // Initialize selectedImages with the IDs from initialSelectedImages
  const [selectedImages, setSelectedImages] = useState<string[]>(
    initialSelectedImages.map((img) => img.id),
  )

  const { t: translate } = useTranslation()

  // Ensure selected images don't exceed maxImages when the prop changes
  useEffect(() => {
    if (selectedImages.length > maxImages) {
      setSelectedImages(selectedImages.slice(0, maxImages))
    }
  }, [maxImages, selectedImages])

  // all selected mediaImageEntities
  const selectedMediaEntities = useMemo(() => {
    return mediaImages.filter((mediaImage) => selectedImages.includes(mediaImage.id))
  }, [mediaImages, selectedImages])

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.1,
        staggerChildren: 0.04,
        when: 'beforeChildren',
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: 5 },
    show: { opacity: 1, y: 0 },
  }

  if (isLoading) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('common.loading')}</DialogTitle>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('modals.mediaImagePickerModal.errorState.title')}</DialogTitle>
          <DialogDescription>
            {translate('modals.mediaImagePickerModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  const deleteSelectedImages = async () => {
    try {
      const imagesToDelete = selectedImages
      for (const imageId of imagesToDelete) {
        await qoursesApi.media.mediaControllerArchiveMediaImage(imageId, 'false')
      }
      refetch()
      setSelectedImages([])
    } catch (e) {
      console.error(e)
    }
  }

  const onImageUploadFinished = () => {
    setActiveTab('imageGallery')
  }

  const handleImageSelection = (mediaImageId: string) => {
    // Normal selection mode for callback
    setSelectedImages((prev) => {
      // If image is already selected, remove it
      if (prev.includes(mediaImageId)) {
        return prev.filter((id) => id !== mediaImageId)
      }

      // If not multiple selection mode, replace any existing selection
      if (!multiple) {
        return [mediaImageId]
      }

      // If multiple selection mode but at max capacity, don't add more
      if (prev.length >= maxImages) {
        return prev
      }

      // Otherwise add the new selection
      return [...prev, mediaImageId]
    })
  }

  const isImageSelectable = (mediaImageId: string) => {
    // get the mediaImage
    const mediaImage = mediaImages.find((img) => img.id === mediaImageId)

    if (
      (browseOnly === false && mediaImage.status === MediaImageEntity.status.UPLOADER_REVIEW) ||
      mediaImage.status === MediaImageEntity.status.PLATFORM_REVIEW
    ) {
      return false
    }

    // In browse-only mode, everything is selectable for archiving
    if (browseOnly) {
      return true
    }

    // If image is already selected, it's selectable (to deselect)
    if (selectedImages.includes(mediaImageId)) {
      return true
    }

    // If not in multiple mode, always allow new selection (it will replace existing)
    if (!multiple) {
      return true
    }

    // In multiple mode, only allow selection if under max capacity
    return selectedImages.length < maxImages
  }

  return (
    <Dynamic.Content
      id={'fullscreen-modal'}
      className="flex flex-col items-center p-3 sm:p-6 overflow-hidden w-screen sm:h-screen max-w-none rounded-none"
    >
      <Tabs
        defaultValue={'imageGallery'}
        className="w-full max-w-5xl mt-6"
        value={activeTab}
        onValueChange={(value) => setActiveTab(value)}
      >
        <TabsList className="flex sm:grid items-center justify-start flex-wrap h-auto space-y-2 sm:space-y-0 sm:grid-cols-2">
          <TabsTrigger value="imageGallery" className="truncate w-full">
            <Images className="size-4 mr-1" />
            {translate('modals.mediaImagePickerModal.tabs.imageGallery')}
          </TabsTrigger>
          <TabsTrigger value="imageUpload" className="truncate w-full">
            <Upload className="size-4 mr-1" />
            {translate('modals.mediaImagePickerModal.tabs.imageUpload')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value={'imageGallery'}>
          {isEmptyMediaImages ? (
            <EmptyState
              title={translate('modals.mediaImagePickerModal.imageGallery.emptyState.title')}
              subtitle={translate('modals.mediaImagePickerModal.imageGallery.emptyState.subtitle')}
              button={
                <Button
                  variant="indigo"
                  className="mt-4"
                  onClick={() => {
                    setActiveTab('imageUpload')
                  }}
                >
                  {translate('modals.mediaImagePickerModal.imageGallery.emptyState.button')}
                </Button>
              }
            />
          ) : (
            <div>
              <AnimatePresence mode={'wait'} initial={false}>
                <motion.div
                  initial="hidden"
                  animate="show"
                  variants={container}
                  className="mt-6 max-h-[40vh] sm:max-h-[50vh] overflow-auto p-4 bg-gray-100 flex gap-x-4 sm:gap-x-4 flex-wrap sm:gap-y-4 gap-y-4 justify-center sm:justify-start rounded-xl shadow-inner border-2 border-gray-200"
                >
                  {mediaImages
                    .sort((a, b) => {
                      return b.createdAt.localeCompare(a.createdAt)
                    })
                    .map((mediaImage) => (
                      <MediaImageDisplay
                        key={mediaImage.cloudflareImageId}
                        mediaImage={mediaImage}
                        onClick={() => {
                          if (isImageSelectable(mediaImage.id)) {
                            handleImageSelection(mediaImage.id)
                          }
                        }}
                        variants={item}
                        mediaImageIds={selectedImages}
                        isSelectable={isImageSelectable(mediaImage.id)}
                      />
                    ))}
                </motion.div>
                <p className="text-sm text-muted-foreground mt-2">
                  {translate('modals.mediaImagePickerModal.imageGallery.subtitle')}
                </p>
                <p className="text-xs text-muted-foreground font-semibold">
                  {translate('modals.mediaImagePickerModal.imageGallery.imageLimit', {
                    imageCount: mediaImages.length,
                  })}
                </p>
                {multiple && (
                  <p className="text-xs text-muted-foreground mt-1">
                    {translate('modals.mediaImagePickerModal.imageGallery.selectionLimit', {
                      selected: selectedImages.length,
                      max: maxImages,
                    }) || `Selected ${selectedImages.length} of ${maxImages} images`}
                  </p>
                )}
                <div className="flex gap-x-3">
                  <Button
                    variant="secondary"
                    className="my-6"
                    disabled={selectedImages.length === 0}
                    onClick={() => {
                      deleteSelectedImages()
                    }}
                  >
                    <Trash className="size-4 mr-1" />
                    {translate('modals.mediaImagePickerModal.imageGallery.buttonDelete')}
                  </Button>
                  {!browseOnly && (
                    <Button
                      variant="indigo"
                      className="my-6"
                      disabled={selectedImages.length === 0}
                      onClick={() => {
                        selectedImagesCallback(selectedMediaEntities)
                        popModal()
                      }}
                    >
                      <Images className="size-4 mr-1" />
                      {translate('modals.mediaImagePickerModal.imageGallery.button')}
                    </Button>
                  )}
                </div>
              </AnimatePresence>
            </div>
          )}
        </TabsContent>
        <TabsContent value={'imageUpload'}>
          <div className="max-w-lg mx-auto mt-10">
            <DialogTitle className="mb-4">
              {translate('modals.mediaImagePickerModal.imageUpload.title')}
            </DialogTitle>
            <MediaImageUploadDropzone
              processFinishCallback={() => {
                onImageUploadFinished()
              }}
            />
            <p className="text-sm text-muted-foreground">
              {translate('modals.mediaImagePickerModal.imageUpload.subtitle')}
            </p>
            <div className=" mt-4">
              <Button
                variant="indigo"
                className="w-full sm:w-auto"
                onClick={() => setActiveTab('imageGallery')}
              >
                <Undo2 className="size-4 mr-1" />
                {translate('modals.mediaImagePickerModal.imageUpload.buttonBack')}
              </Button>
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </Dynamic.Content>
  )
}

function MediaImageDisplay(props: {
  mediaImage: MediaImageEntity
  onClick: () => void
  variants: { hidden: { opacity: number; y: number }; show: { opacity: number; y: number } }
  mediaImageIds: string[]
  isDeleting?: boolean
  isSelectable?: boolean
}) {
  const [isLoading, setIsLoading] = useState(true)

  const displayNotification =
    props.mediaImage.status === MediaImageEntity.status.UPLOADER_REVIEW ||
    props.mediaImage.status === MediaImageEntity.status.PLATFORM_REVIEW

  return (
    <motion.div
      onLoad={() => setIsLoading(false)}
      initial={{
        opacity: 0,
      }}
      whileTap={{
        scale: 0.9,
      }}
      animate={{
        scale: isLoading ? 0.5 : 1,
      }}
      exit={{
        opacity: 0,
      }}
      variants={props.variants}
      className="relative"
    >
      <motion.img
        onClick={props.onClick}
        draggable={false}
        animate={{
          opacity: props.isSelectable === false ? 0.5 : 1,
        }}
        className={cn(
          'size-[100px] sm:size-[180px] rounded-lg border-gray-300 border-4 object-cover',
          props.isSelectable !== false ? 'cursor-pointer' : 'cursor-not-allowed',
          props.mediaImageIds.includes(props.mediaImage.id) && 'border-indigo-500 border-4',
          isLoading && 'border-0 bg-gray-200 rounded-full',
        )}
        src={getImageUrl(props.mediaImage.cloudflareImageId)}
        alt={props.mediaImage.altText}
        loading="lazy"
      />
      <Button
        className="absolute top-3 left-3 hover:bg-gray-600 size-7 sm:size-9"
        size={'icon'}
        variant={'indigo'}
        onClick={(event) => {
          event.stopPropagation()
          pushModal('MediaImageContentReviewModal', {
            mediaImage: props.mediaImage,
          })
        }}
      >
        {displayNotification && (
          <div className="absolute -top-1.5 -left-1.5">
            <NotificationBubble notification={'!'} layoutId={'nsfw' + props.mediaImage.id} />
          </div>
        )}
        <ScanEye className="size-4" />
      </Button>
      {props.mediaImage.nsfw && (
        <VenetianMask className="size-4 absolute bottom-2 right-3 text-white text-opacity-30 pointer-events-none" />
      )}
    </motion.div>
  )
}
