/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationAdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Aligns notification objects for all registered users. Creates missing userCommunicationPreferences and userNotificationPreferences.
     * @param dryRun
     * @returns any Returns the number of created notification objects
     * @throws ApiError
     */
    public notificationAdminControllerAlignUserNotificationObjects(
        dryRun: boolean,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/notification/alignUserNotificationObjects',
            query: {
                'dryRun': dryRun,
            },
        });
    }
}
